// Third-party dependencies
import { useEffect } from 'react'
import { useParams } from '@redwoodjs/router'

// First-party global dependencies
import { useCitiesContext } from 'src/components/CitiesContext/CitiesContext'
import {
  default_filter_state,
  usePropertyFilterState,
} from 'src/components/PropertyFilterContext'

// Wraps property filter state with URL search param hydration/manipulation.
export const usePropertyFilters = () => {
  const filters_state = usePropertyFilterState()
  const search_params = useParams()
  const { all_ids: all_city_ids } = useCitiesContext()
  const { filters, setFilters, setCities } = filters_state

  // Hydrate filter state from URL search params.
  useEffect(() => {
    setFilters((filters) => {
      const loaded = Object.keys(filters).reduce(
        (filters, key) => {
          if (search_params[key] === undefined) return filters

          switch (key) {
            case 'cities':
              filters['cities'] = search_params[key]
                .split(',')
                .map((id) => parseInt(id, 10))
              break

            // Ensure a search_address is always a string type.
            case 'search_address':
              filters['search_address'] = search_params[key]
              break

            default:
              filters[key] = JSON.parse(search_params[key])
          }

          return filters
        },
        { ...filters }
      )

      return loaded
    })
  }, [search_params, setFilters])

  // Default to selecting all cities (when loaded).
  useEffect(() => {
    if (!all_city_ids?.length) return

    setCities((selected: number[]) =>
      selected.length ? selected : all_city_ids
    )
  }, [all_city_ids])

  // Update search params when a filter changes.
  useEffect(() => {
    // TODO: ideally use actual SearchParams to build the URL. Reverted to manual string parsing/building due to unexplicable SearchParam behaviors.
    const filter_param_names = Object.keys(default_filter_state)

    // Create a new object omitting any filter params.
    const params = {
      ...filter_param_names.reduce(
        (params, name) => {
          // Serialize current value, if not default.
          switch (name) {
            case 'cities':
              if (!all_city_ids.every((id) => filters.cities.includes(id)))
                params['cities'] = filters.cities.join(',')
              else delete params['cities']
              break

            default:
              if (
                filters[name] !== default_filter_state[name] &&
                filters[name] !== undefined
              )
                params[name] = JSON.stringify(filters[name]).replaceAll('"', '')
              else delete params[name]
          }

          return params
        },
        { ...search_params }
      ),
    }

    // Encode a new URL and push it to history.
    const { origin, pathname } = window.location
    const path = pathname.split('?')[0]
    const param_string = Object.entries(params)
      .map(([name, value]) => `${name}=${value}`)
      .join('&')
    window.history.pushState(
      {},
      '',
      `${origin}${path}${param_string.length ? '?' : ''}${param_string}`
    )
  }, [filters, all_city_ids, search_params])

  return filters_state
}
