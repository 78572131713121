import { useAuth } from '@redwoodjs/auth'
import { navigate, routes } from '@redwoodjs/router'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'

import { UserMenu } from './components/UserMenu'
import { Navigation } from './components/Navigation'
import { SocialLinks } from './components/SocialLinks'

export const Header = () => {
  const { isAuthenticated: is_authenticated } = useAuth()

  return (
    <>
      <Toolbar
        right={SocialLinks}
        className="m-0 py-1 px-8 surface-600 border-none border-noround"
      />
      <div className="p-component text-center py-6 surface-400">
        <div className="text-5xl font-bold text-800">UPX World 2.0</div>
        <div className="text-xl text-600">
          (
          {`${
            process.env.NODE_ENV === 'development' ? 'DEV' : 'ALPHA'
          } PREVIEW ${process.env.GIT_COMMIT_HASH.toUpperCase()}`}
          )
        </div>
      </div>
      <nav className="shadow-2 sticky top-0 z-2">
        <Navigation className="border-noround px-5">
          {is_authenticated ? (
            <UserMenu />
          ) : (
            <Button
              label="Log In"
              icon="pi pi-power-off"
              className="mr-4"
              onClick={() => navigate(routes.login())}
            />
          )}
        </Navigation>
      </nav>
    </>
  )
}
