import { useEffect, useState } from 'react'
import { Skeleton } from 'primereact/skeleton'
import { ListBox } from 'primereact/listbox'
import { Button } from 'primereact/button'

import { useCitiesContext } from 'src/components/CitiesContext/CitiesContext'
import { usePropertyFilterContext } from '../../PropertyFilterContext'

const renderCityLoading = () => (
  <Skeleton width={`${Math.floor(Math.random() * 60) + 40}%`} height="1rem" />
)

export const CitiesListBox = (props) => {
  const { all: cities, is_loading: is_loading_cities } = useCitiesContext()
  const {
    filters: { cities: selected_cities },
    setCities: setSelectedCities,
  } = usePropertyFilterContext()
  const [cities_by_country, setCitiesByCountry] = useState({})

  const countryOptionGroupTemplate = ({ code: country_code, label }) => (
    <Button
      onClick={() => selectCountry(country_code)}
      label={label}
      className="p-button-secondary"
    />
  )

  const selectCountry = (country_code) => {
    const cities_in_country = cities_by_country[country_code].items.map(
      (city) => city.value
    )

    if (
      cities_in_country.every((city_id) => selected_cities.includes(city_id))
    ) {
      setSelectedCities(
        selected_cities.filter(
          (city_id) => !cities_in_country.includes(city_id)
        )
      )
    } else {
      setSelectedCities(
        Array.from(new Set(selected_cities.concat(cities_in_country)))
      )
    }
  }

  const clearSelection = () => setSelectedCities([])
  const selectAll = () => setSelectedCities(cities.map(({ id }) => id))

  // Initialize city filter data
  useEffect(() => {
    if (is_loading_cities) return // CitiesContext is querying the API.

    const country_groups = {}
    const sorted_cities = [...cities].sort((a, b) =>
      a.name < b.name ? -1 : Number(a.name < b.name)
    )

    for (const city of sorted_cities) {
      const country_code = city.country.id

      if (!country_groups[country_code]) {
        country_groups[country_code] = {
          label: city.country.name,
          code: country_code,
          items: [],
        }
      }

      country_groups[country_code].items.push({
        label: `${city.name}, ${city.state.id}`,
        value: city.id,
        country_code,
      })
    }

    setCitiesByCountry(country_groups)
  }, [cities, is_loading_cities])

  if (is_loading_cities) {
    // CitiesContext is querying the API.
    return (
      <>
        <ListBox
          {...props}
          disabled
          filter
          filterPlaceholder="Search Cities"
          listStyle={{ maxHeight: '300px' }}
          options={[{}, {}, {}, {}, {}, {}, {}, {}]}
          itemTemplate={renderCityLoading}
        />
        <div className="flex m-2">
          <Button label="Clear" className="flex flex-grow-1 mr-1" disabled />
          <Button
            label="Select All"
            className="flex flex-grow-1 ml-1"
            disabled
          />
        </div>
      </>
    )
  }

  return (
    <>
      <ListBox
        {...props}
        options={Object.values(cities_by_country)}
        onChange={({ value }) => setSelectedCities(value)}
        value={selected_cities}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={countryOptionGroupTemplate}
        multiple
        filter
        filterPlaceholder="Search Cities"
        listStyle={{ maxHeight: '300px' }}
      />
      <div className="flex m-2">
        <Button
          label="Clear"
          className="flex flex-grow-1 mr-1"
          onClick={clearSelection}
        />
        <Button
          label="Select All"
          className="flex flex-grow-1 ml-1"
          onClick={selectAll}
        />
      </div>
    </>
  )
}
