// Third-party dependencies
import { Splitter, SplitterPanel } from 'primereact/splitter'
import { ScrollPanel } from 'primereact/scrollpanel'
import { useMemo } from 'react'

// First-party global depencies
import { PropertyFilterProvider } from 'src/components/PropertyFilterContext'
import { PropertyFilterMenu } from 'src/components/PropertyFilterMenu'

// First-party local dependencies
import { usePropertyFilters } from './hooks'

type PropertyLayoutProps = {
  children: React.ReactNode
}

const PropertiesLayout = ({ children }: PropertyLayoutProps) => {
  const { filters, setFilters } = usePropertyFilters()
  const filter_context_value = useMemo(
    () => [filters, setFilters],
    [filters, setFilters]
  )

  return (
    <PropertyFilterProvider value={filter_context_value}>
      <div className="p-component text-700 p-3 ml-6">
        <h1>Property Explorer</h1>
      </div>
      <Splitter>
        <SplitterPanel size={17}>
          <ScrollPanel
            className="sticky"
            style={{ height: 'calc(100vh - 60px)', top: '60px' }}
          >
            <PropertyFilterMenu />
          </ScrollPanel>
        </SplitterPanel>
        <SplitterPanel size={83} minSize={70}>
          <main className="h-full">{children}</main>
        </SplitterPanel>
      </Splitter>
    </PropertyFilterProvider>
  )
}

export default PropertiesLayout
