// Third-party dependencies.
import { useLazyQuery } from '@apollo/client'
import { useState, useMemo } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { classNames } from 'primereact/utils'

export const PLAYER_IDENTIFIERS_QUERY = gql`
  query FindPlayerIdentifiers($like: String!, $take: Int) {
    players: playerIdentifiers(like: $like, take: $take) {
      id
      name
    }
  }
`

export const PlayersAutocomplete = ({
  value,
  onChange = () => {},
  className,
  ...props
}) => {
  const [getPlayerIndentifiers, { loading, error, data }] = useLazyQuery(
    PLAYER_IDENTIFIERS_QUERY
  )

  const searchIdentifiers = ({ query: like }) =>
    getPlayerIndentifiers({ variables: { like, take: 10 } })

  console.log(data?.players)

  return (
    <AutoComplete
      multiple
      autoHighlight
      minLength={3}
      placeholder="Search Players"
      className={classNames('players-autocomplete', className)}
      value={value}
      completeMethod={searchIdentifiers}
      onChange={({ value }) => onChange(value)}
      suggestions={data?.players?.map(({ id }) => id) ?? []}
    />
  )
}
