import { Button } from 'primereact/button'

export const SocialLinks = () => (
  <>
    <Button
      className="p-0 text-sm p-button-secondary text-100"
      tooltip="Join us on Discord"
      tooltipOptions={{ my: 'right', at: 'bottom left' }}
      onClick={() =>
        window.open('https://discord.gg/3MRmyhXdZk', '_blank').focus()
      }
    >
      <i className="pi pi-discord p-1"></i>
    </Button>
  </>
)
