// Third-party dependencies
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import PrimeReact from 'primereact/api'

// First-party dependencies
import { AuthProvider } from 'src/components/AuthProvider/AuthProvider'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

// Third-party styles
import './scaffold.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

// First-party styles
import './index.css'

// Global configuration
PrimeReact.ripple = true

// Main entrypoint
const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <RedwoodApolloProvider>
          <Routes />
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App
