import { navigate, routes } from '@redwoodjs/router'
import { Menu } from 'primereact/menu'

export const Footer = () => {
  const menus = [
    [
      {
        label: 'Explorer',
        items: [
          {
            label: 'Properties',
            command: () => navigate(routes['explorer/properties']()),
          },
          {
            label: 'Players',
            command: () => navigate(routes['explorer/players']()),
          },
        ],
      },
    ],
    [
      {
        label: 'Products',
        items: [],
      },
    ],
    [
      {
        label: 'About',
        items: [
          {
            label: 'Project',
          },
          {
            label: 'Team',
          },
          {
            label: 'Contact Us',
          },
        ],
      },
    ],
    [
      {
        label: 'Legal',
        items: [
          {
            label: 'Privacy Policy',
          },
          {
            label: 'Terms of Service',
          },
        ],
      },
    ],
  ]

  return (
    <footer id="site_footer" className="p-component surface-400 mt-4">
      <div className="px-8 py-6 flex justify-content-evenly">
        {menus.map((model, i) => (
          <Menu key={`footer-menu-${i}`} model={model} />
        ))}
      </div>
      <div className="text-center text-300 mt-3 p-2 surface-600">
        Copyright © UPX World 2021
        {new Date().getFullYear() > 2021 && `-${new Date().getFullYear()}`}. All
        Rights Reserved.
      </div>
    </footer>
  )
}
