// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Router, Route, Redirect, routes, Private } from '@redwoodjs/router'
import UsersLayout from 'src/layouts/UsersLayout'
import PlayersLayout from 'src/layouts/Explorer/PlayersLayout'
import SiteLayout from 'src/layouts/SiteLayout'
import PropertiesLayout from 'src/layouts/Explorer/PropertiesLayout'

const Routes = () => {
  return (
    <Router>
      <Set wrap={UsersLayout}>
        <Route path="/users/new" page={UserNewUserPage} name="newUser" />
        <Route path="/users/{id}/edit" page={UserEditUserPage} name="editUser" />
        <Route path="/users/{id}" page={UserUserPage} name="user" />
        <Route path="/users" page={UserUsersPage} name="users" />
      </Set>
      <Set wrap={SiteLayout}>
        <Route path="/" page={() => <Redirect to={routes['explorer/properties']()} />} name="home" />
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/login/{provider}" page={LoginPage} name="login/provider" />
        <Private unauthenticated={'login'}>
          <Route path="/explorer" page={() => <Redirect to={routes['explorer/properties']()} />} name="explorer" />
          <Set wrap={PropertiesLayout}>
            <Route path="/explorer/properties" page={ExplorerPropertyPropertiesPage} name="explorer/properties" />
            <Route path="/explorer/properties/{id:Int}" page={ExplorerPropertyPropertyPage} name="explorer/property" />
          </Set>
          <Set wrap={PlayersLayout}>
            <Route path="/explorer/players/{id}" page={ExplorerPlayerPlayerPage} name="explorer/player" />
            <Route path="/explorer/players" page={ExplorerPlayerPlayersPage} name="explorer/players" />
          </Set>
        </Private>
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
