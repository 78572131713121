import { ToggleButton } from 'primereact/togglebutton'

export const ToggleFilterButton = ({
  label,
  checked = false,
  onChange = (value) => {},
  ...props
}) => (
  <ToggleButton
    onLabel={label}
    offLabel={label}
    onIcon="pi pi-check"
    offIcon="pi pi-times"
    checked={checked}
    onChange={({ value }) => onChange(value)}
    {...props}
  />
)
