export const GET_AUTH_PROVIDER_ATTRIBUTES = gql`
  query GetAuthProviderAttributes($provider: String!) {
    authProvider(provider: $provider) {
      type
      auth_url
      scopes
      redirect_url
      client_id
    }
  }
`

export function generateRandomString() {
  let randomString = ''
  const randomNumber = Math.floor(Math.random() * 10)

  for (let i = 0; i < 20 + randomNumber; i++) {
    randomString += String.fromCharCode(33 + Math.floor(Math.random() * 94))
  }

  return randomString
}

export const login = async ({ provider, gql_client, state }) => {
  const res = await gql_client.query({
    query: GET_AUTH_PROVIDER_ATTRIBUTES,
    variables: {
      provider,
    },
  })

  const { type, auth_url, client_id, redirect_url, scopes } =
    res.data.authProvider

  if (type === 'oauth') {
    const url = `${auth_url}?response_type=code&client_id=${client_id}&scope=${scopes.join(
      '%20'
    )}&prompt=none&redirect_uri=${encodeURIComponent(
      redirect_url
    )}&state=${state}`

    window.location.href = url
  } else if (
    process.env.NODE_ENV === 'development' &&
    process.env.DEV_SKIP_AUTH &&
    type === 'devskipauth'
  ) {
    const response = await fetch(global.RWJS_API_DBAUTH_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'login',
        provider,
      }),
    })
    return await response.json()
  } else {
    throw new Error(`Auth type "${type}" is not implemented.`)
  }
}
