import {
  createContext,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react'
import { useQuery } from '@redwoodjs/web'

export type City = {
  id: number
  name: string
  state: string
  country: string
}

export type CitiesContextValue = {
  all: City[]
  by_id: {
    [key: number]: City
  }
  by_name: {
    [key: string]: City
  }
  all_ids: number[]
  all_names: string[]
  is_loading: boolean
}

export interface CitiesContextProviderProps {
  value: CitiesContextValue
}

export const DEFAULT_CONTEXT_VALUE: CitiesContextValue = {
  all: [],
  by_id: {},
  by_name: {},
  all_ids: [],
  all_names: [],
  is_loading: false,
}

const GET_CITIES_QUERY = gql`
  query GetCities {
    cities {
      id
      name
      full_name
      state {
        id
        name
      }
      country {
        id
        name
      }
    }
  }
`

export const CitiesContext = createContext(DEFAULT_CONTEXT_VALUE)

CitiesContext.displayName = 'CitiesContext'

export const CitiesProvider = ({
  children,
  value: value_prop,
  ...props
}: PropsWithChildren<Partial<CitiesContextProviderProps>>) => {
  const { loading, error, data } = useQuery(GET_CITIES_QUERY) // TODO: toast on error.
  const [value, setValue] = useState(value_prop ?? DEFAULT_CONTEXT_VALUE)

  useEffect(() => {
    const v = {
      all: [],
      by_id: {},
      by_name: {},
      all_ids: [],
      all_names: [],
      is_loading: loading,
    }

    for (const city of data?.cities ?? []) {
      v.all.push(city)
      v.by_name[city.name] = city
      v.by_id[city.id] = city
      v.all_ids.push(city.id)
      v.all_names.push(city.name)
    }

    setValue(v)
  }, [data, loading])

  // If the value prop changes, override the current context value.
  useEffect(() => {
    if (!value_prop) return

    setValue(value_prop)
  }, [value_prop])

  return (
    <CitiesContext.Provider {...props} value={value}>
      {children}
    </CitiesContext.Provider>
  )
}

export const useCitiesContext = () => useContext(CitiesContext)

export const useCities = (
  ids_or_names?: string[] | number[] | undefined
): City[] | null => {
  const cities = useCitiesContext()

  if (cities.is_loading) return null

  if (!ids_or_names) return Object.values(cities.by_id)

  if (!ids_or_names.length) return []

  if (typeof ids_or_names[0] === 'string')
    return cities.all_names.reduce((selection, name) => {
      if ((ids_or_names as string[]).includes(name))
        selection.push(cities.by_name[name])

      return selection
    }, [])

  return cities.all_ids.reduce((selection, id) => {
    if ((ids_or_names as number[]).includes(id))
      selection.push(cities.by_id[id])

    return selection
  }, [])
}
