import { useRef } from 'react'
import { useAuth } from '@redwoodjs/auth'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Menu } from 'primereact/menu'
import { OverlayPanel } from 'primereact/overlaypanel'

import UserAvatarCell from 'src/components/User/UserAvatarCell'

export const UserMenu = (props) => {
  const {
    isAuthenticated: is_authenticated,
    logOut,
    currentUser: current_user,
  } = useAuth()
  const user_panel_ref = useRef(null)

  if (!is_authenticated) return null

  return (
    <>
      <div className="flex align-items-center">
        <Button
          icon="pi pi-envelope"
          className="flex p-button-rounded p-button-secondary mx-2"
          disabled
        />
        <Button
          icon="pi pi-bell"
          className="flex p-button-rounded p-button-secondary"
          disabled
        />
        <Divider layout="vertical" />
        <Button
          label={`@${current_user?.alias}`}
          className="flex p-button-text p-button-secondary font-semibold py-0 px-2"
          onClick={(e) => user_panel_ref.current.toggle(e)}
        >
          <UserAvatarCell
            id={current_user?.id}
            className="ml-2 shadow-1 border-1 border-400"
            style={{ width: '2.357rem', height: '2.357rem' }}
          />
        </Button>
      </div>
      <OverlayPanel
        className="p-0"
        dismissable
        ref={user_panel_ref}
        id="user_menu_panel"
      >
        <Menu
          model={[
            {
              label: 'Profile',
              icon: 'pi pi-user',
              disabled: true,
            },
            {
              label: 'Properties',
              icon: 'pi pi-home',
              disabled: true,
            },
            {
              label: 'Settings',
              icon: 'pi pi-cog',
              disabled: true,
            },
            {
              separator: true,
            },
            {
              label: 'Log Out',
              command: logOut,
              icon: 'pi pi-power-off',
            },
          ]}
        />
      </OverlayPanel>
    </>
  )
}
