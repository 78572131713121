import { Avatar } from 'primereact/avatar'
import { Skeleton, SkeletonProps } from 'primereact/skeleton'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import type { GetUserAvatarAttributes } from 'types/graphql'

export const QUERY = gql`
  query GetUserAvatarAttributes($id: ID!) {
    user(id: $id) {
      alias
      avatar_url
    }
  }
`

export const Loading = ({ shape = 'circle', ...props }: SkeletonProps) => (
  <Skeleton shape={shape} {...props} />
)

export const Empty = ({ shape = 'circle', defaultImageUrl, ...props }) => {
  return <Avatar {...props} shape={shape} image={defaultImageUrl} />
}

export const Failure = ({ error }: CellFailureProps) => {
  return <div className="col-12 md-3">{error.message}</div>
}

export const Success = ({
  user: { alias, avatar_url },
  label,
  shape = 'circle',
  defaultImageUrl,
  ...props
}: CellSuccessProps<GetUserAvatarAttributes>) => {
  return (
    <Avatar
      {...props}
      shape={shape}
      image={avatar_url ?? defaultImageUrl}
      label={label ?? alias[0].toUpperCase()}
    />
  )
}
