import { AuthProvider as RedwoodAuthProvider } from '@redwoodjs/auth'

import { login, generateRandomString } from './services/client'

export class CustomAuthProvider extends RedwoodAuthProvider {
  constructor(props) {
    super(props)
    this.rwClient.login = login

    if (!localStorage.getItem('discord-oauth-state')) {
      localStorage.setItem('oauth-state', generateRandomString())
    }
  }
}

export const AuthProvider = ({ children, ...props }) => (
  <CustomAuthProvider type="dbAuth" {...props}>
    {children}
  </CustomAuthProvider>
)
