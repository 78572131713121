import { navigate, routes } from '@redwoodjs/router'
import { Menubar } from 'primereact/menubar'

import type { MenuItem, MenuItemTemplateType } from 'primereact/menuitem'

// Subset of MenuItem interface's properties.
// TODO: TypeScript probably has a fancier way to do this than this copy pasta.
interface routeNavOptions {
  replace?: boolean
  icon?: string
  items?: MenuItem[] | MenuItem[][]
  expanded?: boolean
  disabled?: boolean
  target?: string
  style?: object
  className?: string
  template?: MenuItemTemplateType
  [key: string]: any
}

const routeNav = (label, route, options: routeNavOptions = {}): MenuItem => {
  const url = routes[route]()
  const icon = options.icon && `pi pi-${options.icon}`

  const item: MenuItem = {
    label,
    url,
    command: () => navigate(url, { replace: options.replace ?? false }),
  }

  if (icon) item.icon = icon

  if (options.items) item.items = options.items

  return item
}

export const Navigation = ({ children, ...props }) => {
  const items: MenuItem[] = [
    //routeNav('Home', 'home'),
    {
      label: 'Explorer',
      items: [
        routeNav('Properties', 'explorer/properties'),
        //routeNav('Players', 'explorer/players'),
      ],
    },
  ]

  return <Menubar model={items} {...props} end={children} />
}
