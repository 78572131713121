// Third-party dependencies
import { useMemo } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Calendar } from 'primereact/calendar'
import { Divider } from 'primereact/divider'
import { SelectButton } from 'primereact/selectbutton'

// First-party global dependencies
import { PlayersAutocomplete } from 'src/components/PlayersAutocomplete'
import { RangeControl } from 'src/components/RangeControl'
import { usePropertyFilterContext } from 'src/components/PropertyFilterContext'

// First-party local dependencies
import { CitiesListBox } from './components/CitiesListBox'
import { ToggleFilterButton } from './components/ToggleFilterButton'

export const PropertyFilterMenu = ({ ...props }) => {
  const {
    filters: {
      for_sale_upx,
      for_sale_fiat,
      locked,
      max_mint,
      max_mint_date,
      max_up2,
      min_mint,
      min_mint_date,
      min_up2,
      not_for_sale,
      owner_ids,
    },
    setForSaleUpx,
    setForSaleFiat,
    setMintRange,
    setMintDateRange,
    setNotForSale,
    setUp2Range,
    setOwnerIds,
  } = usePropertyFilterContext()

  const fsa = null
  const unminted = false
  const mint_date_range = useMemo(() => {
    const range = []

    if (min_mint_date) {
      range.push(min_mint_date)

      if (max_mint_date) range.push(max_mint_date)
    }

    return range
  }, [min_mint_date, max_mint_date])

  return (
    <Accordion {...props} multiple activeIndex={[0, 1, 2]}>
      <AccordionTab header="Cities" contentClassName="p-0">
        <CitiesListBox style={{ border: 0 }} />
      </AccordionTab>
      <AccordionTab header="Price">
        <div className="mb-3">List Price: (Unavailable)</div>
        <RangeControl suffix=" UPX" disabled />
        <Divider />
        <div className="my-3">Mint Price:</div>
        <RangeControl
          suffix=" UPX"
          minValue={min_mint || undefined}
          maxValue={max_mint}
          onChange={setMintRange}
        />
      </AccordionTab>
      <AccordionTab header="Size">
        <RangeControl
          suffix=" UP2"
          minValue={min_up2}
          maxValue={max_up2}
          onChange={setUp2Range}
        />
      </AccordionTab>
      <AccordionTab header="Owners">
        <PlayersAutocomplete value={owner_ids ?? []} onChange={setOwnerIds} />
      </AccordionTab>
      <AccordionTab header="Tx Date">
        <div className="my-3">Mint Date:</div>
        <Calendar
          selectionMode="range"
          value={mint_date_range}
          onChange={({ value }) => setMintDateRange(value)}
        />
      </AccordionTab>
      <AccordionTab header="Status" disabled>
        <div className="my-3">(Unavailable)</div>
        <div className="flex flex-column">
          <ToggleFilterButton
            label="For Sale (UPX)"
            checked={for_sale_upx}
            onChange={setForSaleUpx}
            className="border-noround"
          />
          <ToggleFilterButton
            label="For Sale (fiat)"
            checked={for_sale_fiat}
            onChange={setForSaleFiat}
            className="border-noround"
          />
          <ToggleFilterButton
            label="Unminted"
            checked={unminted}
            className="border-noround"
          />
          <ToggleFilterButton
            label="Not For Sale"
            checked={not_for_sale}
            onChange={setNotForSale}
            className="border-noround"
          />
          <ToggleFilterButton
            label="Locked"
            checked={locked}
            className="border-noround"
          />
        </div>
        <div className="field mt-2">
          <label htmlFor="filter-fsa">FSA: (Unavailable)</label>
          <SelectButton
            id="filter-fsa"
            className="flex"
            options={[
              { label: 'Include', value: null, className: 'flex flex-grow-1' },
              { label: 'Exclude', value: false, className: 'flex flex-grow-1' },
              { label: 'Only', value: true, className: 'flex flex-grow-1' },
            ]}
            value={fsa}
            disabled
          />
        </div>
      </AccordionTab>
    </Accordion>
  )
}
