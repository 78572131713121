// Third-party dependencies
import { useCallback, useContext, useState } from 'react'

// First-party local dependencies
import type {
  PropertyFilters,
  PropertyFilterName,
} from './PropertyFilterContext'
import {
  PropertyFilterContext,
  default_filter_state,
} from './PropertyFilterContext'

type FilterReducerArgsType = any[]

type FilterReducerType<
  F extends PropertyFilterName,
  A extends FilterReducerArgsType = [PropertyFilters[F]]
> = (...args: A) => PropertyFilters[F]

type FilterNameOrReducer<
  F extends PropertyFilterName,
  A extends FilterReducerArgsType = [PropertyFilters[F]]
> = PropertyFilterName | [PropertyFilterName, FilterReducerType<F, A>]

const useFilterSetter = <
  F extends PropertyFilterName,
  A extends FilterReducerArgsType = [PropertyFilters[F]]
>(
  setter,
  ...reducers: FilterNameOrReducer<F, A>[]
) =>
  useCallback(
    (...values: A) =>
      setter((prev_filters) => ({
        ...prev_filters,
        ...reducers.reduce((filters, reducer) => {
          if (typeof reducer === 'string') {
            const key = reducer

            filters[key] =
              typeof values[0] === 'function'
                ? values[0](prev_filters[key])
                : values[0]
          } else {
            const key = reducer[0]

            filters[key] = reducer[1](
              ...values.map((value) =>
                typeof value === 'function' ? value(prev_filters[key]) : value
              )
            )
          }

          return filters
        }, {}),
      })),
    [setter, reducers]
  )

export const usePropertyFilterState = (
  default_state: undefined | Partial<PropertyFilters> = undefined
) => {
  const [filters, setFilters] = useState(
    default_state
      ? { ...default_filter_state, default_state }
      : { ...default_filter_state }
  )
  const setCities = useFilterSetter<'cities'>(setFilters, 'cities')
  const setForSaleFiat = useFilterSetter<'for_sale_fiat'>(
    setFilters,
    'for_sale_fiat'
  )
  const setForSaleUpx = useFilterSetter<'for_sale_upx'>(
    setFilters,
    'for_sale_upx'
  )
  // TODO: this typing doesn't work here.
  const setMintRange = useFilterSetter<
    'min_mint',
    [PropertyFilters['min_mint'], PropertyFilters['max_mint']]
  >(
    setFilters,
    ['min_mint', (range) => range[0]],
    ['max_mint', (range) => range[1]]
  )
  const setMintDateRange = useFilterSetter<
    'min_mint_date',
    [[PropertyFilters['min_mint_date'], PropertyFilters['max_mint_date']]]
  >(
    setFilters,
    ['min_mint_date', (range) => range[0]],
    ['max_mint_date', (range) => range[1]]
  )
  const setNotForSale = useFilterSetter<'not_for_sale'>(
    setFilters,
    'not_for_sale'
  )
  const setPage = useFilterSetter<'skip', [number]>(setFilters, [
    'skip',
    (page) => page * filters.take,
  ])
  const setPerPage = useFilterSetter<'take'>(setFilters, 'take')

  const setSearchAddress = useFilterSetter<'search_address'>(
    setFilters,
    'search_address'
  )
  // TODO: or here.
  const setSort = useFilterSetter<
    'order_by',
    [PropertyFilters['order_by'], PropertyFilters['order']]
  >(setFilters, 'order_by', 'order')

  const setUp2Range = useFilterSetter<
    'min_up2',
    [PropertyFilters['min_up2'], PropertyFilters['max_up2']]
  >(
    setFilters,
    ['min_up2', (range) => range[0]],
    ['max_up2', (range) => range[1]]
  )

  const setOwnerIds = useFilterSetter<'owner_ids'>(setFilters, 'owner_ids')

  return {
    filters,
    setCities,
    setFilters,
    setForSaleFiat,
    setForSaleUpx,
    setMintRange,
    setMintDateRange,
    setNotForSale,
    setOwnerIds,
    setPage,
    setPerPage,
    setSearchAddress,
    setSort,
    setUp2Range,
  }
}

export const usePropertyFilterContext = () => {
  const [filters, setFilters] = useContext(PropertyFilterContext)
  const setCities = useFilterSetter<'cities'>(setFilters, 'cities')
  const setForSaleFiat = useFilterSetter<'for_sale_fiat'>(
    setFilters,
    'for_sale_fiat'
  )
  const setForSaleUpx = useFilterSetter<'for_sale_upx'>(
    setFilters,
    'for_sale_upx'
  )
  // TODO: this typing doesn't work here.
  const setMintRange = useFilterSetter<
    'min_mint',
    [PropertyFilters['min_mint'], PropertyFilters['max_mint']]
  >(
    setFilters,
    ['min_mint', (range) => range[0]],
    ['max_mint', (range) => range[1]]
  )
  const setMintDateRange = useFilterSetter<
    'min_mint_date',
    [PropertyFilters['min_mint_date'], PropertyFilters['max_mint_date']]
  >(
    setFilters,
    ['min_mint_date', (range) => range[0]],
    ['max_mint_date', (range) => range[1]]
  )
  const setNotForSale = useFilterSetter<'not_for_sale'>(
    setFilters,
    'not_for_sale'
  )
  const setPage = useFilterSetter<'skip', [number]>(setFilters, [
    'skip',
    (page) => page * filters.take,
  ])
  const setPerPage = useFilterSetter<'take'>(setFilters, 'take')
  const setSearchAddress = useFilterSetter<'search_address'>(
    setFilters,
    'search_address'
  )

  // TODO: or here.
  const setSort = useFilterSetter<
    'order_by',
    [PropertyFilters['order_by'], PropertyFilters['order']]
  >(
    setFilters,
    ['order_by', (order_by) => order_by],
    ['order', (order_by, order) => order]
  )

  const setUp2Range = useFilterSetter<
    'min_up2',
    [PropertyFilters['min_up2'], PropertyFilters['max_up2']]
  >(
    setFilters,
    ['min_up2', (range) => range[0]],
    ['max_up2', (range) => range[1]]
  )

  const setOwnerIds = useFilterSetter<'owner_ids'>(setFilters, 'owner_ids')

  return {
    filters,
    setCities,
    setFilters,
    setForSaleFiat,
    setForSaleUpx,
    setMintRange,
    setMintDateRange,
    setNotForSale,
    setOwnerIds,
    setPage,
    setPerPage,
    setSearchAddress,
    setSort,
    setUp2Range,
  }
}
