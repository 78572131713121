// Third-party dependencies
import { useRef } from 'react'
import { Toaster } from 'react-hot-toast'
import { Messages } from 'primereact/messages'
import { ScrollTop } from 'primereact/scrolltop'

// First-party global dependencies
import { CitiesProvider } from 'src/components/CitiesContext/CitiesContext'

// First-party local dependencies
import { Header } from './components/Header'
import { Footer } from './components/Footer'

type SiteLayoutProps = {
  children?: React.ReactNode
}

const SiteLayout = ({ children }: SiteLayoutProps) => {
  const primary_messages = useRef(null)

  return (
    <CitiesProvider>
      <div className="min-h-screen w-full flex flex-column">
        <ScrollTop />
        <Header />
        <Toaster position="top-center" />
        <Messages ref={primary_messages} />
        <main className="min-h-full py-6">{children}</main>
        <Footer />
      </div>
    </CitiesProvider>
  )
}

export default SiteLayout
