import { useMemo, useCallback } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Slider } from 'primereact/slider'

export const RangeControl = ({
  minValue = undefined,
  maxValue = undefined,
  rangeMin = undefined,
  rangeMax = undefined,
  suffix = '',
  disabled = false,
  onChange = ([min, max]: [number | undefined, number | undefined]): void => {},
}) => {
  const range_size = useMemo(() => {
    if (!rangeMax) return

    return rangeMax - rangeMin
  }, [rangeMin, rangeMax])

  const percentToValue = useCallback(
    (percent) => (percent / 100) * range_size + rangeMin,
    [rangeMin, range_size]
  )

  const valueToPercent = useCallback(
    (value) => ((value - rangeMin) / range_size) * 100,
    [rangeMin, range_size]
  )

  const percent_range: [number, number] = useMemo(() => {
    if (!range_size) return

    return [
      valueToPercent(minValue ?? rangeMin),
      valueToPercent(maxValue ?? rangeMax),
    ]
  }, [minValue, maxValue, rangeMin, rangeMax, valueToPercent, range_size])

  const changeValue = ([new_min, new_max]: [
    number | undefined,
    number | undefined
  ]) => {
    if (new_min !== minValue && new_min != undefined) {
      if (rangeMin != undefined && new_min < rangeMin) new_min = rangeMin

      if (new_max != undefined && new_min > new_max) new_min = new_max
    }

    if (new_max !== maxValue && new_max != undefined) {
      if (rangeMax != undefined && new_max > rangeMax) new_max = rangeMax

      if (new_min != undefined && new_max < new_min) new_max = new_min
    }

    if (new_min !== minValue || new_max !== maxValue)
      onChange([new_min ?? undefined, new_max ?? undefined])
  }

  return (
    <>
      <div className="p-input">
        {percent_range && !disabled && (
          <Slider
            className="mx-2 my-3"
            range
            value={percent_range}
            onChange={({ value }) =>
              changeValue([percentToValue(value[0]), percentToValue(value[1])])
            }
          />
        )}
      </div>
      <div className="p-inputgroup mt-1">
        <span
          className="p-inputgroup-addon"
          style={{
            minWidth: '30%',
          }}
        >
          Min
        </span>
        <InputNumber
          placeholder="(None)"
          min={rangeMin}
          max={maxValue}
          value={minValue}
          suffix={suffix}
          onValueChange={({ value }) => changeValue([value, maxValue])}
          disabled={disabled}
        />
      </div>
      <div className="p-inputgroup mt-1">
        <span
          className="p-inputgroup-addon"
          style={{
            minWidth: '30%',
          }}
        >
          Max
        </span>
        <InputNumber
          placeholder="(None)"
          min={minValue}
          max={rangeMax}
          value={maxValue}
          suffix={suffix}
          onValueChange={({ value }) => changeValue([minValue, value])}
          disabled={disabled}
        />
      </div>
    </>
  )
}
