import { createContext } from 'react'

export type PropertyFilters = {
  cities: number[]
  for_sale_fiat: boolean
  for_sale_upx: boolean
  locked: boolean
  max_mint: number | undefined
  max_mint_date: Date | undefined
  max_up2: number | undefined
  min_mint: number | undefined
  min_mint_date: Date | undefined
  min_up2: number | undefined
  not_for_sale: boolean
  order: 'asc' | 'desc'
  order_by: string
  owner_ids: string[] | undefined
  search_address: string | undefined
  skip: number
  take: number
}

export type PropertyFilterName = keyof PropertyFilters

export type PropertyFilterContextValue = [
  PropertyFilters,
  (state: PropertyFilters | undefined | ((PropertyFilters) => void)) => void
]

export const default_filter_state: PropertyFilters = {
  cities: [],
  for_sale_fiat: true,
  for_sale_upx: true,
  locked: false,
  max_mint: undefined,
  max_mint_date: undefined,
  max_up2: undefined,
  min_mint: undefined,
  min_mint_date: undefined,
  min_up2: undefined,
  not_for_sale: true,
  order: 'desc',
  order_by: 'id',
  owner_ids: undefined,
  search_address: undefined,
  skip: 0,
  take: 50,
}

export const default_context_value: PropertyFilterContextValue = [
  {
    ...default_filter_state,
  },
  () => {},
]

export const PropertyFilterContext = createContext(default_context_value)

PropertyFilterContext.displayName = 'PropertyFilterContext'
